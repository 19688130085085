import { Component, OnInit } from '@angular/core';
import { SessionCartService } from '../../event/session-cart/session-cart.service';
import { ActivatedRoute } from '@angular/router';
import { RegistrationServiceFactory } from '../registration-service.factory';
import { LabelsService } from '../../../services/labels.service';
import { Observable } from 'rxjs';
import { ActiveEventService } from 'src/app/services/active-event.service';
import {Event} from 'src/app/models/Event';


@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
    private readableEventId: string;
    event: Event;


    constructor(
        private route: ActivatedRoute,
        private cartService: SessionCartService,
        private labelsService: LabelsService,
        private registrationServiceFactory: RegistrationServiceFactory,
        private activeEventService: ActiveEventService,
    ) {}

    ngOnInit() {
        this.readableEventId = this.route.snapshot.queryParams['id'];

        this.loadData();

        this.cartService.flushCart(this.readableEventId);
        this.registrationServiceFactory.dispose(this.readableEventId);
    }

    public translateLabel(translationKey: string, defaultValue: string): Observable<string> {
        return this.labelsService.translateLabel(translationKey, defaultValue);
    }

    private async loadData() {
        await this.loadEvent();

        if (this.event) {
            // if (this.event.allowCustomAgenda) {
            //     this.loadSessions();
            // }
            
            // this.loadEventRegistrationCount();

            // if (this.event.registrationForm) {
            //     if (this.showRegistrationForm()) {
            //         this.cntActiveDataRetrievals += 1;
            //     }
            //     return;
            // }

            // this.loadEventPasses();
        }

        // this.loadCustomRegistrationFields();
    }

    private async loadEvent() {
        // this.cntActiveDataRetrievals += 1;
        this.activeEventService.getEvent(this.readableEventId).subscribe(
            event => {
                this.event = event;

                // this.cntActiveDataRetrievals -= 1;
            },
            // (error: LocalizableError) => this.handleDataRetrievalError(error)
        );
    }
}
