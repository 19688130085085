import { EVENT_SERVICE } from './../../providers/service.providers';
import { EventService } from '../../services/event.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Event } from '../../models/Event';
import { LocalizableError } from 'src/app/models/LocalizableError';
import { debounceTime } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { LabelsService } from 'src/app/services/labels.service';
import { TimeDisplayConfig } from '../../../assets/config/config';
import { DatePipe } from '@angular/common';
import { DateFormatter } from 'src/app/helpers/DateFormatter';
// import { Router } from "@angular/router"
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from "@angular/platform-browser";

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    public allEvents: Event[];
    public filteredEvents: Event[];
    public isLoading: boolean;
    public error?: LocalizableError;
    private subject: Subject<string> = new Subject();
    private timeDisplayConfig: any;
    public event: Event;
    public readableEventId: string;
    public data: any;
    public sdata: any;
    public reserved: number;

    constructor(
        @Inject(EVENT_SERVICE) private eventService: EventService,
        private labelsService: LabelsService,
        private datePipe: DatePipe,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private metaService: Meta) {
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Konference a události KPMG");

        this.timeDisplayConfig = TimeDisplayConfig;

        this.readableEventId = this.route.snapshot.queryParams['id'];
        console.log("this.readableEventId:", this.readableEventId);
        this.data = this.route.snapshot.queryParams['data'];
        console.log("this.data:", this.data);
        this.sdata = this.route.snapshot.queryParams['sdata'];
        console.log("this.sdata:", this.sdata);
        this.reserved = this.route.snapshot.queryParams['reserved'];
        console.log("this.reserved:", this.reserved);

        this.loadPublishedEvents();

        console.log('Home loaded');

        // this.subject.pipe(
        //     debounceTime(300)
        // ).subscribe(searchTextValue => {
        //     this.handleSearch(searchTextValue);
        // });
    }

    private loadPublishedEvents() {
        this.isLoading = true;
        this.eventService.getPublishedEvents().subscribe(
            events => {
                const now = new Date();
                const tomorrow = new Date();
                now.setDate(now.getDate());
                tomorrow.setDate(tomorrow.getDate() + 1);
                const profiledEvents = events.filter((it: Event) => {
                    return it.customFields["kpmgmars_profileid"] === 1
                        && new Date(it.startDate) >= now
                });

                // const registrEndDateEvents = profiledEvents.filter((it: Event) => {
                //     return (new Date(it.stopRegistrationDate).getTime() < 0 && !it.setRegistrationEndDate && new Date(it.customFields["kpmgmars_portalvisibilitydate"]).getTime() >= now.getTime())
                //         || !(it.setRegistrationEndDate && new Date(it.stopRegistrationDate).getTime() < now.getTime())
                // });

                const registrEndDateEvents = profiledEvents
                    .filter((it: Event) =>
                        (new Date(it.stopRegistrationDate).getTime() < 0 && !it.setRegistrationEndDate) ||
                        !(it.setRegistrationEndDate && new Date(it.stopRegistrationDate).getTime() < now.getTime())
                    )
                    .filter((it: Event) => new Date(it.customFields["kpmgmars_portalvisibilitydate"]).getTime() <= now.getTime());

                console.log("registrEndDateEvents: ", registrEndDateEvents);

                // const registrEndDateEvents = profiledEvents.filter((it: Event) => {
                //     return (new Date(it.stopRegistrationDate).getTime() < 0 && !it.setRegistrationEndDate)
                //         || !(it.setRegistrationEndDate && new Date(it.stopRegistrationDate).getTime() < now.getTime())
                // });

                // for (let index = 0; index < profiledEvents.length; index++) {
                //     const element = profiledEvents[index];
                //     console.log(element.eventName);
                //     console.log(new Date(element.stopRegistrationDate).getTime(), "<0", new Date(element.stopRegistrationDate).getTime() < 0);
                //     console.log(new Date(element.stopRegistrationDate).getTime(), now.getTime(), new Date(element.stopRegistrationDate).getTime() < now.getTime(), "<now");
                //     console.log("setRegistrationEndDate: ", element.setRegistrationEndDate);

                //     // if (new Date(element.stopRegistrationDate).getTime() < 0 && !element.setRegistrationEndDate) {
                //     //     console.log(" -> ", element.eventName);
                //     // }
                // }

                const sortedEvents = registrEndDateEvents.sort((a, b) => { return new Date(a.startDate).getTime() - new Date(b.startDate).getTime() });
                this.allEvents = sortedEvents; //events;
                this.filteredEvents = sortedEvents;
                this.isLoading = false;

                for (let index = 0; index < sortedEvents.length; index++) {
                    const element = sortedEvents[index];
                    if (element.readableEventId == this.readableEventId) {
                        this.event = element;
                        let navUrl: string;
                        if (this.data != null && this.sdata != null && this.reserved != null) {
                            navUrl = "/event/registration?id=" + this.readableEventId + "&data=" + this.data + "&sdata=" + this.sdata + "&reserved=" + this.reserved;
                        }
                        else {
                            navUrl = "/event/registration?id=" + this.readableEventId //+ "&data=" + this.data + "&sdata=" + this.sdata + "&reserved=" + this.reserved;
                        }
                        console.log(navUrl);
                        this.router.navigateByUrl(navUrl);
                    }
                }
            },
            (error: LocalizableError) => this.handleErrorResponse(error)
        );
    }

    private handleErrorResponse(error: LocalizableError) {
        this.error = error;
        this.isLoading = false;
    }

    public translateLabel(translationKey: string, defaultValue: string): Observable<string> {
        return this.labelsService.translateLabel(translationKey, defaultValue);
    }

    private getAreaLabel(event: Event): string {
        let date = this.getDateString(event);
        let building = event.building ? `at ${event.building.name}` : "";
        return `${event.eventName} on ${date} ${building}`;
    }

    public getDateString(event: Event): string {
        let dateSettings = DateFormatter.getDateSettings();

        if (dateSettings.convertToLocalDate) {
            return DateFormatter.formatRangedDate(this.datePipe, event.startDateUTC, event.endDateUTC);
        }
        else {
            return DateFormatter.formatRangedDate(this.datePipe, event.startDate, event.endDate, event.timeZone);
        }
    }

    handleSearch(searchText: string) {
        if (searchText) {
            searchText = searchText.toLocaleLowerCase();
        }
        this.filteredEvents = this.allEvents.filter((it: Event) => {
            return it.eventName.toString().toLocaleLowerCase().includes(searchText);
        });
    }

    search(searchText: string) {
        this.subject.next(searchText);
    }

    orderEvents(order: string) {
        if (order === "date-asc") {
            this.filteredEvents.sort((a, b) => { return <any>new Date(a.startDate.toString()) - <any>new Date(b.startDate.toString()) });
        } else if (order === "date-desc") {
            this.filteredEvents.sort((a, b) => { return <any>new Date(b.startDate.toString()) - <any>new Date(a.startDate.toString()) });
        } else if (order === "name-asc") {
            this.filteredEvents.sort((a, b) => a.eventName.localeCompare(b.eventName));
        } else if (order === "name-desc") {
            this.filteredEvents.sort((a, b) => b.eventName.localeCompare(a.eventName));
        }
    }
}
